import React, { Component } from 'react';
import './ResumePage.scss';

class ResumePage extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    colorClasses = [
        'keywords',
        'functions',
        'tags',
        'attributes',
        'operators',
        'strings',
        'numbers'
    ];

    randomClass = () =>
        this.colorClasses[Math.floor(Math.random() * this.colorClasses.length)];

    render() {
        return (
            <div className='ResumePage'>

                <div className='pdf-notification' onClick={() => window.open("https://drive.google.com/file/d/1NAd3n_qU_5ewgxj79VVgNj_qKdvg6N9M/view", '_blank').focus()}>
                    <p>PDF?</p>
                </div>

                <div className='resume-section'>
                    <h2>Work Experience</h2>

                    <div className='job-card'>
                        <p className='job-company'>webAI</p>

                        <div className='title-line'>
                            <p className='job-title'>Senior Software Engineer</p>
                            <p className='time-span'>May 2024 - December 2024</p>
                        </div>

                        <p className='job-description'>
                            Architected, designed, and built a desktop application that installs and updates webAI's software suite.
                            Built out several programs and services to support the webAI ecosystem. 
                            Worked closely on a service that allows users to install AI tools and models and run them locally,  
                            empowering customers to use their private data with local AI models. 
                            Assisted on the peer-to-peer networks and distributed software side to widen our network and reach.
                        </p>

                        <div className='job-tags'>
                            {[
                                'Rust',
                                'TypeScript',
                                'Go',
                                'Tauri',
                                'React',
                                'Python',
                                'Systems',
                                'Electron',
                                'Docker',
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className='job-card'>
                        <p className='job-company'>TrovaTrip</p>

                        <div className='title-line'>
                            <p className='job-title'>Software Engineer</p>
                            <p className='time-span'>Aug 2021 - May 2024</p>
                        </div>

                        <p className='job-description'>
                            Started as a full-stack developer. 
                            Helped build powerful front-end UIs and design 
                            complex React architecture. Spent a lot of time creating functional and robust 
                            logic for our backend to evaluate and update our 
                            elaborate data structures.
                            <br />
                            <br />
                            My talents led me to become a valued back-end 
                            engineer, working exclusively on projects such as analytics, 
                            data modeling, API design, infrastructure, and more. 
                            I led multiple projects and had ownership over many areas 
                            within the TrovaTrip software suite.
                            <br />
                            <br />
                            I was eventually selected to join the mobile team, to help 
                            design and build a new API & infrastructure 
                            that supported the up-coming mobile app. I worked closely with 
                            the mobile developers to ensure support for all versions, past and future, 
                            which included stability as well as efficient load times as well as  
                            offline support.
                        </p>

                        <div className='job-tags'>
                            {[
                                'TypeScript',
                                'React',
                                'Node',
                                'MongoDB',
                                'Fastify',
                                'Git',
                                'AWS',
                                'Google Cloud',
                                'Heroku',
                                'Docker',
                                'Postman'
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className='job-card'>
                        <p className='job-company'>Weentar</p>

                        <div className='title-line'>
                            <p className='job-title'>Software Engineer <span className='comments'>- Contract</span></p>
                            <p className='time-span'>Jun 2021 - Aug 2021</p>
                        </div>

                        <p className='job-description'>
                            I joined Weentar as a contract engineer & architect, with a three month window to build a microservice array to support a crypto-currency based social media platform.
                            <br />
                            <br />
                            I helped design their API, data models, and architecture. The API was a series of Rust lambdas hosted on AWS. 
                            Thanks to my efforts, I was able to build a robust and secure system that could handle the high traffic of a social media platform. 
                            Not only that but I deployed the lambdas on the edge, along with a replicated/distributed MongoDB database, to ensure low latency around the globe and high availability.
                            <br />
                            <br />
                            I was invited to join the team at TrovaTrip around the time when my contract ended, so I did not choose to continue with Weentar.
                            Not long after leaving, a large crypto crash ended the short-lived company.
                        </p>

                        <div className='job-tags'>
                            {[
                                'Rust',
                                'Typescript',
                                'MongoDB',
                                'Git',
                                'AWS',
                                'Docker'
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className='job-card'>
                        <p className='job-company'>Tournament Kings</p>

                        <div className='title-line'>
                            <p className='job-title'>Lead Full Stack Engineer</p>
                            <p className='time-span'>Nov 2019 - Jun 2021</p>
                        </div>

                        <p className='job-description'>
                            At Tournament Kings, I was the lead web developer. I
                            architected, designed, and built their web application. With OAuth and PayPal integrations, 
                            I made sure to design a secure and robust application. 
                            <br />
                            <br />
                            I joined the company when they were in their early stages. 
                            I was directly responsible for hiring many key engineers.
                            Working closely with the CEO & CTO, I helped build a strong team of engineers.
                            <br />
                            <br />
                            I also did extensive work on the back-end, building out a robust API, implementing business analytics, 
                            and systems level development on our main product. 
                        </p>

                        <div className='job-tags'>
                            {[
                                'JavaScript',
                                'Node',
                                'Sass',
                                'Docker',
                                'Postgres',
                                'SQL',
                                'Nginx',
                                'GoLang',
                                'Python',
                                'Microservices',
                                'Serverless',
                                'AWS Cloudwatch',
                                'AWS Cognito',
                                'AWS Amplify',
                                'Git',
                                'GitHub',
                                'Linux',
                                'Shell Scripting'
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className='job-card'>
                        <p className='job-company'>Optis</p>

                        <div className='title-line'>
                            <p className='job-title'>Junior Software Engineer</p>
                            <p className='time-span'>Oct 2018 - Nov 2019</p>
                        </div>

                        <p className='job-description'>
                            At Optis, I was a junior full-stack developer. My
                            role was to help out where ever needed on a C# .NET
                            application, whether that be in the front-end
                            JavaScript (using KendoUI and JQuery), the C# core
                            of the application, or the SQL Server database. I was
                            in charge of maintaining their front-end, including
                            complex tables, company specific features, and
                            advanced search algorithms. We were frequently
                            adding new features to an old application, and
                            fixing many bugs. The database was extremely large
                            and SQL queries had to be carefully written.
                        </p>

                        <div className='job-tags'>
                            {[
                                'JavaScript',
                                'C#',
                                '.NET',
                                'CSS',
                                'SQL',
                                'Razor MVC',
                                'JQuery',
                                'AWS',
                                'SSRS',
                                'TFS',
                                'LINQ'
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className='job-card'>
                        <p className='job-company'>Trilogy Eduction</p>

                        <div className='title-line'>
                            <p className='job-title'>Assistant Instructor</p>
                            <p className='time-span'>Jan 2018 - Nov 2018</p>
                        </div>

                        <p className='job-description'>
                            At Trilogy Education, I was an Assistant Teacher for
                            a coding boot-camp. My main role was to assist the
                            teacher in anyway. This usually consisted of helping
                            students during class, tutoring students in
                            after-hours, and working with students one-on-one. I
                            occasionally taught lessons, took attendance, and
                            grading coding assignments. Grading homework
                            consisted of rigorous code review via 
                            GitHub and running the application on Heroku. 
                            Often times my work consisted of ruthless
                            debugging and bug hunting. IE: "Why doesn't my code work?"
                        </p>

                        <div className='job-tags'>
                            {[
                                'JavaScript',
                                'Node.js',
                                'React',
                                'HTML',
                                'CSS',
                                'SQL',
                                'MongoDB',
                                'JQuery',
                                'NPM',
                                'Yarn',
                                'Git',
                                'Express',
                                'Python'
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <div className='job-card'>
                        <p className='job-company'>Various</p>

                        <div className='title-line'>
                            <p className='job-title'>Prior Experience</p>
                            <p className='time-span'>2010 - 2017</p>
                        </div>

                        <p className='job-description'>
                            Before starting my career in software engineering
                            I held various positions in the auto industry. 
                            Most of that time was spent working at Porche & Toyota. 
                            Those jobs taught me a lot about professionalism, a strong work ethic, 
                            and much more. While these are not relevant to my engineering skills,
                            I do think they are relevant to my to my subsequent success.
                        </p>

                        <div className='job-tags'>
                            {[
                                'Professionalism',
                                'Client Satisfaction',
                                'Logistics',
                                'Business Etiquette',
                                'Computer Inventory Systems',
                            ].map((tag, index) => (
                                <p className={this.randomClass()} key={index}>
                                    {tag}
                                </p>
                            ))}
                        </div>
                    </div>

                    <br />
                    <br />

                    <h2>Education</h2>

                    <div className='education-card'>
                        <p className='education-type'>Web Development</p>

                        <div className='title-line'>
                            <p className='school-title'>University of Denver</p>
                            <p className='time'>2017</p>
                        </div>

                        <p className='job-description'>
                            At the University of Denver, I
                            was taught the basics of web development and
                            JavaScript. I learned how to use React, Node,
                            JQuery, SQL, MongoDB, and much more. This was a
                            starting point for me to expand my knowledge through
                            self-teaching and research.
                        </p>
                    </div>

                    <div className='education-card'>
                        <p className='education-type'>General Education</p>

                        <div className='title-line'>
                            <p className='school-title too-long'>
                                Arapahoe Community College
                            </p>
                            <p className='time'>2011</p>
                        </div>

                        <p className='job-description'>
                            I recieved my general education degree from Arapahoe
                            Community college in 2011. Graduated one year ahead of my
                            expected graduation date.
                        </p>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />

                </div>
            </div>
        );
    }
}

export default ResumePage;
